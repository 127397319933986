
import { useNavigate } from "react-router";
import { useState } from "react";
import Logo from '../../images/axyma-logo.svg'
import Auth from '../../services/Auth';
import Login from "../../services/Login";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { ButtonDiv, Form, Input, Main } from "./styles";
import { Oval } from 'react-loading-icons';

const loginService = new Login();

export default function FormLogin() {
  const navigate = useNavigate();
  const [user, setUser] = useState({ login: '', password: '' })
  const [inputType, setInputType] = useState("password");
  const [loading, setLoading] = useState(false);

  const saveLoginToken = async () => {
    try {
      const loginData = await loginService.getLoginInfo('/loginByToken', { email: user.login, senha: user.password })
      if (loginData) {
        return loginData;
      } if (!loginData) {
        throw new Error('Usuario invalido')
      } else return false;
    } catch (err) {
      const error: { message: string } = err as { message: string }
      throw new Error(error.message)
    }
  }

  const login = async () => {
    try {
      setLoading(true)
      const loginData = await saveLoginToken();
      if (loginData) {
        const auth = new Auth(loginData.uid, loginData.userName, loginData.empresa);
        await auth.getToken();
        const userData = await auth.confirmLoginAndGetData();
        if (userData) {
          if (userData.role.length === 13 && typeof window !== 'undefined') {
            window.localStorage.setItem('userData', JSON.stringify(userData));
            navigate("/empresas");
          } else throw new Error('Este usuário não tem permissão de acesso')
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      const error: { message: string } = err as { message: string }
      throw new Error(error.message)
    }
  }

  const toggleEye = () => inputType === "password" ? setInputType("text") : setInputType("password")

  return (
    <>
      <ToastContainer />
      <Main data-theme="light">
        <Form onSubmit={async (event: React.FormEvent) => {
          event.preventDefault()
          await toast.promise(login(), {
            pending: 'Entrando...',
            success: 'Bem vindo!',
            error: 'Falha!'
          })
        }}
        >
          <span style={{ color: 'white' }}>Interface adminstrativa</span>
          <img
            src={Logo}
            width={200}
            height={200}
            alt="Axyma"
          />
          <Input
            required
            value={user.login}
            onChange={(event) => setUser({ ...user, login: event.target.value })}
            type="text"
            placeholder="Nome de usuário"
            className={'input is-small'} />
          <ButtonDiv>
            <Input
              required
              style={{ position: 'relative' }}
              value={user.password}
              onChange={(event) => setUser({ ...user, password: event.target.value })}
              type={inputType}
              placeholder="Senha"
              className={`input is-small is-fullwidth`} />
            <button
              type='button'
              onClick={toggleEye}
              className="button is-small is-white"
              style={{ position: 'absolute' }}>
              {inputType === "password" ? (
                <AiOutlineEye />
              ) : (
                <AiOutlineEyeInvisible />
              )
              }
            </button>
          </ButtonDiv>
          <button
            disabled={user.login === '' || user.password === ''}
            type="submit"
            className="button is-white is-small">
            {loading
              ? <Oval height={10} speed={.5} stroke="#44c1cd" className="loading" />
              : 'Entrar'}
          </button>
        </Form>
      </ Main>
    </>
  );
}