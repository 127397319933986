import axios, { AxiosError, AxiosResponse } from "axios";

export type body = {
  userToken: String;
  username: String;
  userEmpresa: String;
};

class Empresas {
  private api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/empresa`,
  });

  getAll = async (body: body) => {
    try {
      const data: AxiosResponse = await this.api.post("/getAll", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  novaEmpresa = async (body: body, empresa: { nome: String; cnpj: string; emailNotificacao: string | null; status: string }) => {
    try {
      const data: AxiosResponse = await this.api.post("/novaEmpresa", {
        ...body,
        empresa,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  editaEmpresa = async (body: body, empresa: { id: string; nome: String; cnpj: string; emailNotificacao: string | null; status: string }) => {
    try {
      const data: AxiosResponse = await this.api.post("/editaEmpresa", {
        ...body,
        empresa,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  migraForm = async (body: body, empresaId: string, formMigrado: boolean) => {
    try {
      const data: AxiosResponse = await this.api.post("/migraForm", {
        ...body,
        empresaId,
        formMigrado,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  ligaDesligaMigracao = async (body: body, migrado: boolean, empresaId: string) => {
    try {
      const data: AxiosResponse = await this.api.post("/ligaDesligaMigracao", {
        ...body,
        migrado,
        empresaId,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  concedeRetiraAcessoPlanoDeAcao = async (body: body, planoDeAcao: boolean, empresaId: string) => {
    try {
      const data: AxiosResponse = await this.api.post("/concedeRetiraAcessoPlanoDeAcao", {
        ...body,
        planoDeAcao,
        empresaId,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  setDominio = async (body: body, empresaId: string, dominio: string) => {
    try {
      const data: AxiosResponse = await this.api.post("/setDominio", {
        ...body,
        empresaId,
        dominio,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };
}

export default Empresas;
