import Login from '../../services/Login';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { useState } from "react";
import { Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from "@mui/material";
import { BsBuildings } from "react-icons/bs";
import { FaChartBar } from "react-icons/fa";
import { LuUserSquare2 } from "react-icons/lu";
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import { IoMdMenu } from "react-icons/io";
import { IoChevronBackOutline } from "react-icons/io5";
import { IoMdExit } from "react-icons/io";
import { IoSettingsSharp } from "react-icons/io5";

const loginService = new Login();

export default function Header() {
  const [open, setOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const location = useLocation();

  const { mutate } = useMutation({
    mutationKey: ['LOGOUT'],
    mutationFn: async () => {
      toast.warn('Saindo!')
      const loggedData = localStorage.getItem('loggedData');
      if (loggedData) {
        const parsed = JSON.parse(loggedData);
        await loginService.logout(parsed?.userToken);
        localStorage.clear();
        window.location.href = window.location.origin + "/";
      }
    }
  })

  const drawerWidth = 180;

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));
  interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
  }

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })<AppBarProps>(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    variants: [
      {
        props: ({ open }) => open,
        style: {
          marginLeft: drawerWidth,
          width: `calc(100% - ${drawerWidth}px)`,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
      },
    ],
  }));

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      variants: [
        {
          props: ({ open }) => open,
          style: {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
          },
        },
        {
          props: ({ open }) => !open,
          style: {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
          },
        },
      ],
    }),
  );

  return (
    <>
      <div style={{ marginBottom: '100px' }}>
        <AppBar sx={{ backgroundColor: '#614BA6' }} position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(!open)}
              edge="start"
              sx={[
                {
                  marginRight: 5,
                },
                open && { display: 'none' },
              ]}
            >
              <IoMdMenu />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Interface administrativa Axyma
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={() => setOpen(!open)}>
              <IoChevronBackOutline />
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List sx={{ width: 'fit-content' }}>
            <ListItem
              onClick={() => navigate('/empresas')}
              key={'Empresas'}
              disablePadding
              sx={{ display: 'block' }}>
              <ListItemButton
                sx={[
                  {
                    backgroundColor: `${location.pathname === '/empresas' ? '#614BA6' : 'inherit'}`,
                    color: `${location.pathname === '/empresas' ? '#FFFFFF' : 'gray'}`,
                    minHeight: 48,
                    px: 2.5,
                  },
                  open
                    ? {
                      justifyContent: 'initial',
                    }
                    : {
                      justifyContent: 'center',
                    },
                ]}
              >
                <ListItemIcon
                  sx={[
                    {
                      color: `${location.pathname === '/empresas' ? '#FFFFFF' : 'gray'}`,
                      minWidth: 0,
                      justifyContent: 'center',
                    },
                    open
                      ? {
                        mr: 3,
                      }
                      : {
                        mr: 'auto',
                      },
                  ]}
                >
                  <BsBuildings />
                </ListItemIcon>
                <ListItemText
                  primary={'Empresas'}
                  sx={[
                    open
                      ? {
                        opacity: 1,
                      }
                      : {
                        opacity: 0,
                      },
                  ]}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              onClick={() => navigate('/dashboard')}
              key={'dashboard'}
              disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={[
                  {
                    backgroundColor: `${location.pathname === '/dashboard' ? '#614BA6' : 'inherit'}`,
                    color: `${location.pathname === '/dashboard' ? '#FFFFFF' : 'gray'}`,
                    minHeight: 48,
                    px: 2.5,
                  },
                  open
                    ? {
                      justifyContent: 'initial',
                    }
                    : {
                      justifyContent: 'center',
                    },
                ]}
              >
                <ListItemIcon
                  sx={[
                    {
                      color: `${location.pathname === '/dashboard' ? '#FFFFFF' : 'gray'}`,
                      minWidth: 0,
                      justifyContent: 'center',
                    },
                    open
                      ? {
                        mr: 3,
                      }
                      : {
                        mr: 'auto',
                      },
                  ]}
                >
                  <FaChartBar />
                </ListItemIcon>
                <ListItemText
                  primary={'Dashboard'}
                  sx={[
                    open
                      ? {
                        opacity: 1,
                      }
                      : {
                        opacity: 0,
                      },
                  ]}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              onClick={() => navigate('/users')}
              key={'users'}
              disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={[
                  {
                    backgroundColor: `${location.pathname === '/users' ? '#614BA6' : 'inherit'}`,
                    color: `${location.pathname === '/users' ? '#FFFFFF' : 'gray'}`,
                    minHeight: 48,
                    px: 2.5,
                  },
                  open
                    ? {
                      justifyContent: 'initial',
                    }
                    : {
                      justifyContent: 'center',
                    },
                ]}
              >
                <ListItemIcon
                  sx={[
                    {
                      color: `${location.pathname === '/users' ? '#FFFFFF' : 'gray'}`,
                      minWidth: 0,
                      justifyContent: 'center',
                    },
                    open
                      ? {
                        mr: 3,
                      }
                      : {
                        mr: 'auto',
                      },
                  ]}
                >
                  <LuUserSquare2 />
                </ListItemIcon>
                <ListItemText
                  primary={'Usuários'}
                  sx={[
                    open
                      ? {
                        opacity: 1,
                      }
                      : {
                        opacity: 0,
                      },
                  ]}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              onClick={() => navigate('/settings')}
              key={'configuracoes'}
              disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={[
                  {
                    backgroundColor: `${location.pathname === '/settings' ? '#614BA6' : 'inherit'}`,
                    color: `${location.pathname === '/settings' ? '#FFFFFF' : 'gray'}`,
                    minHeight: 48,
                    px: 2.5,
                  },
                  open
                    ? {
                      justifyContent: 'initial',
                    }
                    : {
                      justifyContent: 'center',
                    },
                ]}
              >
                <ListItemIcon
                  sx={[
                    {
                      color: `${location.pathname === '/settings' ? '#FFFFFF' : 'gray'}`,
                      minWidth: 0,
                      justifyContent: 'center',
                    },
                    open
                      ? {
                        mr: 3,
                      }
                      : {
                        mr: 'auto',
                      },
                  ]}
                >
                  <IoSettingsSharp />
                </ListItemIcon>
                <ListItemText
                  primary={'Configurações'}
                  sx={[
                    open
                      ? {
                        opacity: 1,
                      }
                      : {
                        opacity: 0,
                      },
                  ]}
                />
              </ListItemButton>
            </ListItem>
          </List>
          <List style={{ marginTop: `auto` }} >
            <ListItem onClick={() => mutate()}>
              <ListItemButton
                sx={[
                  {
                    color: 'gray',
                    minHeight: 48,
                    px: 2.5,
                  },
                  open
                    ? {
                      justifyContent: 'initial',
                    }
                    : {
                      justifyContent: 'center',
                    },
                ]}
              >
                <ListItemIcon
                  sx={[
                    {
                      minWidth: 0,
                      justifyContent: 'center',
                    },
                    open
                      ? {
                        mr: 3,
                      }
                      : {
                        mr: 'auto',
                      },
                  ]}
                >
                  <IoMdExit />
                </ListItemIcon>
                <ListItemText
                  primary={'Sair'}
                  sx={[
                    open
                      ? {
                        opacity: 1,
                      }
                      : {
                        opacity: 0,
                      },
                  ]}
                />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
        </Drawer>
      </div>
    </>
  )
}