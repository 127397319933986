import axios, { AxiosError, AxiosResponse } from "axios";

export type body = {
  userToken: String;
  username: String;
  userEmpresa: String;
};

export type versionType = {
  os: String,
  versao: String
}

export default class Versions {
  private api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/versao`,
  });

  getVersion = async (body: body) => {
    try {
      const data: AxiosResponse = await this.api.post("/getVersion", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  setVersion = async (body: body, version: versionType) => {
    try {
      const data: AxiosResponse = await this.api.post("/setVersion", {
        ...body,
        ...version,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };
}
