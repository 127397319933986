import Header from "../Header/Header";
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import register from '../../services/PowerBi';
import { toast } from 'react-toastify';
import UserComponent, { userBackObj } from './UserComponent'
import Users from "../../services/User";
import AttToken from "../../services/AttToken";
import { Autocomplete, Skeleton, TextField } from "@mui/material";
import { Content, Main, Principal } from "./styles";
import { empresaType } from "../Empresas/EmpresasCadastradas";
import Empresas from "../../services/Empresas";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales'
import { FaRegTrashAlt } from "react-icons/fa";
import { FaRegSave } from "react-icons/fa";
import { RxUpdate } from "react-icons/rx"

const empresaService = new Empresas();

export type UserAccessType = {
  [userName: string]: string[]
}

export type empresaReportsType = {
  [id: string]: singleEmpresaType
}

export type singleEmpresaType = {
  datasetId: string,
  datasetWorkspaceId: string
  id: string,
  name: string,
  users: empresaReportsType,
}

const usersService = new Users();
const servicePowerBi = new register();

export default function DashboardComponent() {
  const [url, setUrl] = useState<string>('');
  const [empresa, setEmpresa] = useState<string>('');
  const [selectedReport, setSelectedReport] = useState<singleEmpresaType | null>(null);
  const [usersAdded, setUsersAdded] = useState<any | null>(null);
  const [users, setUsers] = useState<userBackObj[] | null>(null)
  const [workspaceId, setWorkspaceId] = useState<string | null>(null)
  const [user, setUser] = useState<string>('')
  const [userReports, setUserReports] = useState<singleEmpresaType[] | null>(null)
  const [loadingEmpresasReport, setLoadingEmpresasReport] = useState<boolean>(false)

  const { data: empresasData } = useQuery({
    queryKey: ['GET_EMPRESAS'],
    queryFn: async () => {
      const token = await AttToken()
      if (token) {
        const response = await empresaService.getAll(token)
        return response.empresas
      }
    },
    keepPreviousData: true,
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });
  const [loading, setLoading] = useState(false)

  async function getEmpresaReports(value: { empresa: string, user: string }) {
    setLoadingEmpresasReport(true)
    const token = await AttToken()
    if (token) {
      const args = {
        empresa: value.empresa,
        userAdm: 'admin-axyma',
      }
      const response = await servicePowerBi.getEmpresaReports(token.userToken, args)
      if (response) {
        setWorkspaceId(Object.keys(response)[0])
        setUserReports(Object.values(response)[0] as singleEmpresaType[])
      }
      return setLoadingEmpresasReport(false)
    }
  }

  const { mutate, isLoading: loadingRegister } = useMutation({
    mutationKey: ['REGISTER_WORKSPACE'],
    mutationFn: async (value: { url: string, empresa: string, user: string }) => {
      toast.warn('Salvando')
      const token = await AttToken()
      if (token) {
        const args = {
          empresa: value.empresa,
          userAdm: 'admin-axyma',
          url: value.url
        }
        const response = await servicePowerBi.registerWorkSpace(token.userToken, args)
        return response
      }
    },
    onSuccess: () => {
      const value = { empresa: empresa, user: user }
      getEmpresaReports(value)
      setUrl(``)
      toast.success('Salvo')
    }
  })

  const { mutate: updateLicense } = useMutation({
    mutationKey: ['updateLicense'],
    mutationFn: async () => {
      toast.warn('Salvando')
      const token = await AttToken()
      if (token) {
        const userA: any = {}
        Object.values(userReports as any)?.forEach((elem: any) => {
          userA[`${elem.id}/users`] = [elem.users][0]
        })
        const args = {
          empresa: empresa,
          userAdm: user,
        }
        const update: any = { [selectedReport?.datasetWorkspaceId as string]: { ...userA, } }
        const response = await servicePowerBi.manageLicence(token.userToken, args, update)
        return response
      }
    },
    onSuccess: () => {
      toast.success('Salvo')
    }
  })

  const { mutate: updateWorkspace } = useMutation({
    mutationKey: ['UPDATE_WORKSPACE'],
    mutationFn: async () => {
      toast.promise(async () => {
        const token = await AttToken()
        if (token) {
          const args = {
            empresa: empresa,
            userAdm: 'admin-axyma',
            workspaceId: workspaceId
          }
          const response = await servicePowerBi.powerbiUpdateWorkspace(token.userToken, args)
          if (response) {
            const value = { empresa, user }
            setSelectedReport(null)
            getEmpresaReports(value)
          }
          return response
        }
      }, {
        pending: 'Atualizando workspace',
        success: 'Atualizado com sucesso',
        error: 'Erro ao atualizar workspace'
      })
    },
  })

  const { mutate: removeDashBoard, isLoading: loadingRemove } = useMutation({
    mutationKey: ['REMOVE_DASHBOARD'],
    mutationFn: async () => {
      const token = await AttToken()
      if (token) {
        await servicePowerBi.deleteWorkspace(token.userToken, token.username, token.userEmpresa, empresa)
      }
    },
    onSuccess: () => {
      const value = { empresa: empresa, user: user }
      getEmpresaReports(value)
      setSelectedReport(null)
      setUserReports(null)
      setUrl(``)
    }
  })

  const renderSwitch = (value: string) => {
    switch (String(value)) {
      case 'rls_gerente':
        return (
          'Gerente'
        )
      case 'rls_cliente':
        return (
          'Cliente'
        )
      case 'rls_conjunto':
        return (
          'Conjunto'
        )

      case 'rls_empresa':
        return (
          'Empresa'
        )
      default:
        return (
          'Todos'
        )
    }
  }

  const columns: GridColDef[] = [
    { field: 'nome', headerName: 'Nome', width: 250 },
    { field: 'email', headerName: 'E-mail', width: 250 },
    { field: 'id', headerName: 'User Name', width: 150 },
    { field: 'role', headerName: 'Perfil', width: 100 },
    {
      field: 'matricula', headerName: 'Função', width: 100, valueFormatter: (params) => {
        return renderSwitch(params)
      }
    },
    {
      field: 'remove', headerName: 'Remover', width: 100, renderCell: (params: GridRenderCellParams) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button
            className="button is-danger is-small"
            onClick={() => {
              setUsersAdded(usersAdded?.filter((e: userBackObj) => e.id !== params.id))
              if (userReports) {
                delete userReports[selectedReport?.id as any].users[params.id]
              }
            }}
          >
            Remover&nbsp;<FaRegTrashAlt />
          </button>
        </div>
      )
    },
  ];

  return (
    <Main data-theme="light">
      <Header />
      <span style={{ textAlign: 'center', width: '100%', fontSize: '30px' }}>Dashboards</span>
      <Content>
        <Principal>
          {!empresasData ? <Skeleton height={60} /> :
            <Autocomplete
              isOptionEqualToValue={(option, value) => option.value === value.value}
              disableClearable
              className='column'
              noOptionsText={'Sem equipes correspondentes'}
              size="small"
              id="disable-close-on-select"
              filterSelectedOptions
              options={empresasData
                ?.sort((a: empresaType, b: empresaType) => { return a?.nome?.toLowerCase() > b?.nome?.toLowerCase() ? 1 : - 1 })
                ?.filter((elem: empresaType) => elem?.status === 'ativo')
                ?.map((option: empresaType) => ({
                  label: option.nome,
                  value: option.id,
                }))}
              onChange={async (event: any, newValue: { label: string, value: string } | null, reason: any, details: any) => {
                setUserReports(null)
                setSelectedReport(null)
                setWorkspaceId(null)
                setEmpresa(newValue?.value as string);
                setLoading(true)
                const token = await AttToken();
                if (token) {
                  const body = { ...token }
                  const result = await usersService.getAllByCompany(body, newValue?.value as string);
                  if (result) {
                    setUsers(result)
                    const usuario = process.env.REACT_APP_PROJECT_NAME === 'Axyma Administrativo Interno HOMOL'
                      ? 'admin-axyma'
                      : result.find((user: userBackObj) => user.id.includes('suporte')).id
                    setUser(usuario)
                    const value = { empresa: newValue?.value as string, user: usuario }
                    getEmpresaReports(value)
                    setLoading(false)
                    return result
                  }
                }
              }
              }
              renderInput={(params) => <TextField {...params} label={'Selecione a empresa'} />}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.value}>
                  <span>{option.label}</span>
                </li>)}
            />}
          {loadingEmpresasReport || loading || loadingRegister ? <Skeleton height={60} /> : <>
            {empresa?.length > 0 && <>
              {!userReports
                ? <form>
                  <label>
                    <p style={{ textAlign: 'left', margin: '2px', fontWeight: '600' }}>Insira uma URL de relatório do PowerBI para cadastrar:</p>
                    <div style={{ display: 'flex' }}>
                      <input value={url} onChange={(e) => {
                        setUrl(e.target.value)
                      }} className='input is-small is-fullwidth' />
                      <button onClick={(e) => {
                        e.preventDefault()
                        const value = { url: url, empresa: empresa, user: user }
                        mutate(value)
                      }}
                        disabled={url === ''}
                        type='submit'
                        className='button is-success is-small'
                      >
                        Enviar url
                      </button>
                    </div>
                  </label>
                </form>
                :
                <>
                  {loadingEmpresasReport
                    ? <>
                      <Skeleton variant="rounded" height={35} width={150} sx={{ marginBottom: '10px' }} />
                      <Skeleton variant="rounded" height={40} />
                    </>
                    : <>
                      {userReports
                        && <Autocomplete
                          disableClearable
                          className='column'
                          noOptionsText={'Sem dashboards'}
                          size="small"
                          id="disable-close-on-select"
                          filterSelectedOptions
                          disabled={!userReports}
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          options={Object.values(userReports)
                            ?.sort((a: any, b: any) => { return a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : - 1 })
                            ?.map((option: any) => ({
                              label: option.name,
                              value: option.id,
                            })) || []}
                          onChange={async (event: any, newValue: { label: string, value: string } | null, reason: any, details: any) => {
                            setUsersAdded(null)
                            const report = Object.values(userReports as any)?.find((ele: any) => ele.id === details.option.value)
                            if (report as singleEmpresaType) {
                              setSelectedReport(report as singleEmpresaType);
                              const mappedUsers = Object.entries((report as singleEmpresaType)?.users).map((el: any) => ({
                                id: el[0],
                                value: el[1]
                              }))
                              const userList = users?.filter((user: any) => mappedUsers?.some((userId: any) => userId.id === user.id));
                              userList?.forEach((ele: any) => {
                                if (ele) {
                                  ele.matricula = mappedUsers?.find((elem: any) => elem.id === ele.id)?.value
                                }
                              })
                              setUsersAdded(userList);
                            }
                          }}
                          renderInput={(params) => <TextField {...params} label={'Selecione a dashboard'} />}
                          renderOption={(props, option, { selected }) => (
                            <li {...props} key={option.value}>
                              <span>{option.label}</span>
                            </li>)}
                        />}
                    </>
                  }
                </>
              }
            </>}
          </>}
          {userReports && userReports &&
            <div style={{ marginTop: '20px' }}>
              <div style={{ display: 'flex', marginBottom: '.5em' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <div style={{ marginBottom: '.5em', display: 'flex', width: 'fit-content', alignItems: 'flex-end' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <button
                        disabled={!workspaceId}
                        style={{ marginBottom: '5px' }}
                        className="button is-small is-info"
                        onClick={() => updateWorkspace()}>
                        Update Workspace&nbsp;<RxUpdate />
                      </button>
                      <button
                        disabled={
                          !usersAdded?.length
                          || !selectedReport?.name
                        }
                        onClick={() =>
                          updateLicense()
                        } className='button is-small is-success'>Salvar alterações&nbsp;<FaRegSave />
                      </button>
                    </div>&nbsp;
                    <button
                      disabled={loadingRemove || loadingEmpresasReport}
                      onClick={() =>
                        removeDashBoard()
                      } className='button is-small is-danger'>Remover workspace&nbsp;<FaRegTrashAlt />
                    </button>
                  </div>
                  {selectedReport?.name &&
                    <>
                      Usuários adicionados
                      <div style={{ height: 'fit-content' }}>
                        {usersAdded &&
                          <DataGrid
                            autoHeight
                            sx={{ '--css-1jlz3st': 'display:  none' }}
                            getRowHeight={() => 'auto'}
                            getRowId={(row: any) => row.id}
                            editMode="row"
                            rows={usersAdded}
                            columns={columns}
                            initialState={{
                              pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                              },
                            }}
                            pageSizeOptions={[10, 25]}
                            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                          />
                        }
                      </div>
                      Usuários para adicionar
                      {users && <UserComponent
                        userReports={userReports}
                        usersAdded={usersAdded}
                        setUsersAdded={setUsersAdded}
                        selectedReport={selectedReport}
                        userData={users
                          ?.sort((a: userBackObj, b: userBackObj) => { return a.nome?.toLowerCase() > b.nome?.toLowerCase() ? 1 : -1 }) as userBackObj[]} />
                          }
                    </>
                  }
                </div>
              </div>
            </div>}
        </Principal>
      </Content>
    </ Main>
  );
}