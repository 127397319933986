import { Content, Principal } from '../components/Dashboard/styles'
import EmpresasComponent from '../components/Empresas/EmpresasComponent'

export default function Empresas() {
  return (
    <Content>
      <Principal>
        <EmpresasComponent />
      </Principal>
    </Content>
  )
}
