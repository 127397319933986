import axios, { AxiosError } from "axios";
import { body } from "./Empresas";
import { toast } from "react-toastify";

class Users {
  private api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/usuarios`,
  });

  getAllByCompany = async (body: body, empresaId: string) => {
    try {
      const data = await this.api.post("/getAllByCompany", {
        ...body,
        empresaId,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.log("erro --->", error.response);
        console.error(error.message);
      }
    }
  };

  getAdminUsers = async (body: body) => {
    try {
      const data = await this.api.post("/getAdminUsers", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.log("erro --->", error.response);
        console.error(error.message);
      }
    }
  };

  editUser = async (body: body, userAlias: string, user: any) => {
    try {
      const data = await this.api.post("/editUser", {
        ...body,
        userAlias,
        user,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        const dataError = error.response?.data as unknown as any;
        const erro = dataError.message;
        toast.error(String(erro));
      }
    }
  };
}
export default Users;
