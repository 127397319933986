import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp
} from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales'
import { useEffect, useState } from 'react';
import { FaSave } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { MdCancel } from "react-icons/md";
import { singleEmpresaType } from './DashboardComponent';

export type userBackObj = {
  email: string,
  id: string,
  matricula: string,
  nome: string,
  role: string,
}

export default function UserComponent({
  usersAdded,
  setUsersAdded,
  userData,
  userReports,
  selectedReport,
}: {
  usersAdded: userBackObj[],
  setUsersAdded: Function,
  userData: userBackObj[],
  userReports: singleEmpresaType[] | null,
  selectedReport: singleEmpresaType | null
}) {

  const [rows, setRows] = useState<userBackObj[] | GridRowsProp | null>(null);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const optionsMap = [
    { value: 'Selecione', label: 'Selecione' },
    { value: 'rls_cliente', label: 'Cliente' },
    { value: 'rls_conjunto', label: 'Conjunto' },
    { value: 'rls_empresa', label: 'Empresa' },
    { value: 'rls_gerente', label: 'Gerente' },
    { value: 'all', label: 'Total' }
  ]

  useEffect(() => {
    setRows(userData.filter((elem) => !usersAdded?.includes(elem))?.map(ele => ({ ...ele, matricula: "Selecione" })))
}, [usersAdded])


const handleEditClick = (id: GridRowId) => () => {
  setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
};

const handleSaveClick = (id: GridRowId) => () => {
  setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
};

const handleCancelClick = (id: GridRowId) => () => {
  setRowModesModel({
    ...rowModesModel,
    [id]: { mode: GridRowModes.View, ignoreModifications: true }
  });

};

const processRowUpdate = (newRow: GridRowModel) => {
  const updatedRow = { ...newRow, isNew: false };
  setRows(rows?.map((row) => (row.id === newRow.id ? updatedRow : row)) as userBackObj[]);
  return updatedRow;
};

const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
  setRowModesModel(newRowModesModel);
};

const handleRowEditStop: GridEventListener<"rowEditStop"> = (
  params,
  event
) => {
  if (params.reason === GridRowEditStopReasons.rowFocusOut) {
    event.defaultMuiPrevented = true;
  }
};

const columns: GridColDef[] = [
  { field: 'nome', headerName: 'Nome', width: 250 },
  { field: 'email', headerName: 'E-mail', width: 250 },
  { field: 'id', headerName: 'User Name', width: 150 },
  { field: 'role', headerName: 'Perfil', width: 100 },
  {
    field: 'matricula', headerName: 'Função', width: 100, editable: true,
    type: "singleSelect", valueOptions: optionsMap,
  },
  {
    field: "actions",
    type: "actions",
    headerName: "Editar função",
    width: 120,
    cellClassName: "actions",
    getActions: ({ id }) => {
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<FaSave />}
            label="Save"
            sx={{
              color: "primary.main"
            }}
            onClick={handleSaveClick(id)}
          />,
          <GridActionsCellItem
            icon={<MdCancel />}
            label="Cancel"
            className="textPrimary"
            onClick={() => {
              handleCancelClick(id)
            }}

            color="inherit"
          />
        ];
      }

      return [
        <GridActionsCellItem
          icon={<MdEdit />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(id)}
          color="inherit"
        />,
      ];
    }
  },
  {
    field: 'add', headerName: 'Adicionar', width: 100, renderCell: (params) => {
      const onClick = () => {
        let singleUser: any = {}
        const currentUser: any | unknown = rows?.find((curr: any) => curr.id === params?.row?.id)
        setUsersAdded([...usersAdded, { ...currentUser, matricula: [params?.row?.matricula] }])
        singleUser[currentUser.id] = [currentUser.matricula]
        let usersFromSelect = (userReports as singleEmpresaType[])[selectedReport?.id as any].users
        usersFromSelect = { ...usersFromSelect, ...singleUser };
        if (userReports)
          userReports[selectedReport?.id as any].users = usersFromSelect
      }
      return (
        <div style={{ height: "100%", display: 'flex', alignItems: 'center' }}>
          <button
            disabled={params.row.matricula === 'Selecione'}
            onClick={() => onClick()}
            className='button is-small is-info'>
            Adicionar
          </button>
        </div>
      );
    }
  },
];

return (
  <>{userData &&
    <DataGrid
      autoHeight
      getRowId={(row: any) => row.id}
      sx={{ '--DataGrid-overlayHeight': 'fit-content' }}
      getRowHeight={() => 'auto'}
      editMode="row"
      rowModesModel={rowModesModel}
      onRowModesModelChange={handleRowModesModelChange}
      onRowEditStop={handleRowEditStop}
      processRowUpdate={processRowUpdate}
      rows={rows?.filter((e) => !usersAdded?.some((user: any) => user.nome === e.nome))}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 10 },
        },
      }}
      slotProps={{
        toolbar: { setRows, setRowModesModel }
      }}
      pageSizeOptions={[10, 25]}
      localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
    />
  }
  </>
)
}