import axios, { AxiosError, AxiosResponse } from "axios";

export type UserAccessType = {
  [userName: string]: string[];
};

export type RequestType = {
  [key: string]: { [key: string]: UserAccessType };
} | null;
export type userReportsType = {
  datasetId: string;
  datasetWorkspaceId: string;
  id: string;
  name: string;
  users: string[];
  reportId?: string;
};

type argsType = {
  empresa: string;
  userAdm: string;
  workspaceId?: string | null;
};

type argsEmbed = {
  empresa: string;
  userAdm: string;
  workspaceId: string;
  reportId: string;
  identityUser: string;
};

type argsRegister = {
  empresa: string;
  userAdm: string;
  url: string;
};

class PowerBi {
  private api = axios.create({
    baseURL: process.env.REACT_APP_API_CLOUD_HOST,
  });

  private apiAxyma = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/powerBi`,
  });

  getEmpresaReports = async (uid: string, texto: argsType) => {
    try {
      const args = JSON.stringify(texto);
      const data: AxiosResponse = await this.api.get("/powerbiGetEmpresaReports", {
        headers: {
          uid: uid,
          args: args,
        },
      });
      return data.data.reports;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  getUserReports = async (uid: string, texto: argsType) => {
    try {
      const args = JSON.stringify(texto);
      const data: AxiosResponse = await this.api.get("/powerbiGetUserReports", {
        headers: {
          uid,
          args,
        },
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  getEmbed = async (uid: string, texto: argsEmbed) => {
    try {
      const args = JSON.stringify(texto);
      const data: AxiosResponse = await this.api.get("/powerbiGetEmbedToken", {
        headers: {
          uid,
          args,
        },
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  registerWorkSpace = async (uid: string, texto: argsRegister) => {
    try {
      const args = JSON.stringify(texto);
      const data: AxiosResponse = await this.api.get("/powerbiRegisterWorkspace", {
        headers: {
          uid,
          args,
        },
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  manageLicence = async (uid: string, texto: argsType, updates?: RequestType) => {
    try {
      const args = JSON.stringify(texto);
      const data: AxiosResponse = await this.api.post(
        "/powerbiManageLicenses",
        {
          updates,
        },
        {
          headers: {
            uid,
            args,
          },
        }
      );
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  getAllReports = async (uid: string, texto: argsType, updates: RequestType) => {
    try {
      const args = JSON.stringify(texto);
      const data: AxiosResponse = await this.api.post(
        "/powerbiGetAllReports",
        {
          updates,
        },
        {
          headers: {
            uid,
            args,
          },
        }
      );
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  powerbiUpdateWorkspace = async (uid: string, args?: argsType) => {
    try {
      const data: AxiosResponse = await this.api.get("/powerbiUpdateWorkspace", {
        headers: {
          uid,
          args: JSON.stringify(args),
        },
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  deleteWorkspace = async (userToken: string, username: string, userEmpresa: string, workspaceId: String) => {
    try {
      const data: AxiosResponse = await this.apiAxyma.post("/deleteWorkspace", {
        userToken,
        username,
        userEmpresa,
        workspaceId,
      });
      return data.data.gerencias;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };
}

export default PowerBi;
