import { Autocomplete, Skeleton, TextField } from '@mui/material'
import { ModalCardBody, ModalFooter, P } from '../Empresas/styles'
import AttToken from '../../services/AttToken'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify';
import queryClient from '../../services/query';
import Users from '../../services/User';

const userService = new Users();

type userProps = {
  openModal: boolean,
  setOpenModal: Function,
  user: any,
  setUser: Function,
  empresas: any[]
  isLoading: boolean,
}

export default function AddEmpresasModal({
  openModal,
  setOpenModal,
  user,
  setUser,
  empresas,
  isLoading
}: userProps) {

  const { mutate, isLoading: loadingChange } = useMutation({
    mutationKey: ['CHANGE_USER'],
    mutationFn: async () => {
      try {
        toast.warn('Salvando!')
        const token = await AttToken()
        if (token) {
          const response = await userService.editUser(token, user?.id, user)
          return response
        }
      } catch (err) {
        toast.error('Erro ao salvar')
      }
    },
    onSuccess: () => {
      queryClient.resetQueries(['GET_USERS'])
      setOpenModal(!openModal)
      toast.success('Salvo com sucesso!')
    }
  })


  return (
    <div className={`${openModal ? 'modal is-active' : 'modal'}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className={`modal-card-head`} style={{ backgroundColor: '#f5f5f5', color: 'gray !important' }}>
          <P className={`modal-card-title`}>{`Adicionar empresas para ${user?.nome}`}</P>
          <button
            onClick={() => setOpenModal(!openModal)}
            style={{ backgroundColor: 'red' }}
            className="is-danger delete"
            aria-label="fechar" />
        </header>
        <ModalCardBody className={`modal-card-body`}>
          {isLoading ? <Skeleton variant='rounded' height={40} /> :
            <Autocomplete
              value={user?.empresas && user?.empresas?.map((ele: string) => (
                {
                  value: ele || 'Não encontrado',
                  label: empresas?.find((elem: any) => elem.id === ele)?.nome || 'Não encontrado'
                }
              )) || undefined}
              multiple
              className='column'
              noOptionsText={'Sem equipes correspondentes'}
              size="small"
              id="disable-close-on-select"
              filterSelectedOptions
              isOptionEqualToValue={(option, value) => option.value === value.value}
              options={empresas && empresas
                ?.sort((a: any, b: any) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                ?.filter((elem: any) => elem.status === 'ativo')
                ?.map((option: any) => ({
                  label: option.nome,
                  value: option.id,
                }))}
              onChange={(event: any, newValue: { label: string, value: string }[] | null) => {
                let empresasArray = [] as string[]
                newValue?.forEach((ele) => {
                  empresasArray?.push(ele?.value)
                })
                setUser({ ...user, empresas: empresasArray })
              }}
              renderInput={(params) => <TextField {...params} label={'Selecione a(s) empresa(s)'} />}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.value}>
                  <span>{option.label}</span>
                </li>)}
            />
          }
        </ModalCardBody>
        <ModalFooter className={`modal-card-foot`}>
          <div className="buttons">
            <button
              disabled={loadingChange}
              onClick={() => mutate()}
              className="button is-success">
              Salvar
            </button>
          </div>
        </ModalFooter>
      </div>
    </div>
  )
}
