import { Content, Principal } from '../components/Dashboard/styles'
import UsersList from '../components/Users/UsersList'

export default function Users() {
  return (
    <Content>
      <Principal>
        <UsersList />
      </Principal>
    </Content>
  )
}
