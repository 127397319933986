import styled from "styled-components";

export const Form = styled.form`
  justify-content: center;
  /* padding: 20px 65px 50px 65px; */
  height: fit-content;
  min-height: fit-content;
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const P = styled.p`
  color: gray;
  text-align: center;
`;

export const Span = styled.span`
  color: gray;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
`;

export const ModalCardBody = styled.section`
  /* height: 150px; */
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: x-large;
  background-color: white;
  color: black;
`;

export const ModalFooter = styled.footer`
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Main = styled.main`
  height: 100%;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

export const Tabs = styled.div`
  .tabs {
    padding: 50px 100px 20px 100px;
    border: 0;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 500;
    text-decoration: none;
    --bulma-scheme-main: light-gray;
    --bulma-background: #00ebc7;
  }
`;

export const TabStyle = styled.a`
  border: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  text-decoration: none;
`;
