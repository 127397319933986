import { useMutation, useQuery } from 'react-query'
import { Main } from '../Empresas/styles'
import Header from '../Header/Header'
import AttToken from '../../services/AttToken'
import Versions from '../../services/version'
import { Skeleton, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { Content, Principal } from '../Dashboard/styles'
import { useMask } from '@react-input/mask';
import { FaRegEdit } from "react-icons/fa";
import { FcCancel } from "react-icons/fc";
import { FaSave } from "react-icons/fa";
import { InputContainer } from './styles'
import { toast } from 'react-toastify'
import queryClient from '../../services/query'

const versionService = new Versions();

type versionType = {
  android: string,
  ios: string,
}

export default function SettingsComp() {
  const [version, setVersion] = useState<versionType>({} as versionType)
  const [edit, setEdit] = useState<string>('');
  const inputRef = useMask({ mask: '_._._', replacement: '_' });

  function checkChar(value: string): undefined | string {
    if (value === '') {
      return ''
    }
    const pattern = /[0-9]/
    if (pattern.test(value)) {
      return value;
    }
    return
  }

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['GET_VERSION'],
    queryFn: async () => {
      const token = await AttToken()
      if (token) {
        const response = await versionService.getVersion(token)
        return response
      }
    },
    keepPreviousData:true,
    refetchOnMount:'always',
    refetchOnWindowFocus: false
  })

  const { mutate } = useMutation({
    mutationKey: ['SET_VERSION'],
    mutationFn: async (ver: { os: string, versao: string }) => {
      toast.warn(`Alterando versão ${edit}`)
      try{
        const token = await AttToken();
        if (token) {
         const res = await versionService.setVersion(token, ver)
         res && toast.success(`Versão ${edit} alterada`)
         queryClient.resetQueries(['GET_VERSION'])
        }
      } catch(err) {
        toast.error(`Erro ao alterar versão ${edit}`) 
        queryClient.resetQueries(['GET_VERSION'])
      }
    },
  })

  useEffect(() => {
    if (data && !isLoading) {
      setVersion({ ...data.versoes })
      localStorage.setItem('versions', JSON.stringify(data))
    }
  }, [isLoading, isFetching])

  return (
    <Main>
      <Header />
      <span style={{ textAlign: 'center', width: '100%', fontSize: '30px' }}>Configurações</span>
      <Content>
        <Principal>
          {
            isLoading || isFetching
              ?
              <InputContainer>
                <div style={{display: 'flex'}}>
                  <Skeleton variant='rounded' height={50} width={220}/>&nbsp;<Skeleton variant='rounded' height={45}  width={100}/>
                </div>
                <div style={{display: 'flex'}}>
                  <Skeleton variant='rounded' height={50} width={220}/>&nbsp;<Skeleton variant='rounded' height={45}  width={100}/>
                </div>
              </InputContainer>
              :
              <InputContainer>
                <div>
                  <TextField
                    inputRef={inputRef}
                    disabled={edit !== 'ios'}
                    label="Versão IOS"
                    sx={{ width: 'fit-content' }}
                    value={version?.ios}
                    onChange={(event) => {
                      const ver = checkChar(event.target.value)
                      if (ver === undefined) return;
                      setVersion({ ...version, ios: ver })
                    }}
                  />&nbsp;
                  <button
                    disabled={(edit !== '') || isLoading}
                    className='button is-info'
                    onClick={() => setEdit('ios')}
                  >
                    Editar
                    &nbsp;<FaRegEdit />
                  </button>&nbsp;
                  {edit === 'ios'
                    && <>
                      <button
                        className='button is-warning'
                        onClick={() => {
                          setEdit('')
                          const ver = localStorage.getItem('versions')
                          if (ver) {
                            const parsed = JSON?.parse(ver)
                            setVersion({ ...version, ios: parsed?.versoes?.ios })
                          }
                        }}
                      >
                        Cancelar&nbsp;
                        <FcCancel />
                      </button>&nbsp;
                      <button
                        className='button is-success'
                        onClick={() => {
                          mutate({ os: 'ios', versao: version.ios })
                          setEdit('')
                        }}
                        disabled={version.ios?.length < 5}
                      >
                        Salvar&nbsp;
                        <FaSave />
                      </button>
                    </>
                  }
                </div>
                <div>
                  <TextField
                    inputProps={{ maxLength: 4 }}
                    disabled={edit !== 'android'}
                    label={'Versão Android'}
                    sx={{ width: 'fit-content' }}
                    value={version?.android}
                    onChange={(event) => {
                      const ver = checkChar(event.target.value)
                      if (ver === undefined) return;
                      setVersion({ ...version, android: ver })
                    }}
                  />&nbsp;
                  <button
                    className='button is-info'
                    onClick={() => setEdit('android')}
                    disabled={(edit !== '') || isLoading}
                  >
                    Editar
                    &nbsp;<FaRegEdit />
                  </button>&nbsp;
                  {edit === 'android'
                    && <>
                      <button
                        className='button is-warning'
                        onClick={() => {
                          setEdit('')
                          const ver = localStorage.getItem('versions')
                          if (ver) {
                            const parsed = JSON?.parse(ver)
                            setVersion({ ...version, android: parsed?.versoes?.android })
                          }
                        }}
                      >
                        Cancelar&nbsp;
                        <FcCancel />
                      </button>&nbsp;
                      <button
                        disabled={version.android?.length < 4}
                        className='button is-success'
                        onClick ={() => {
                          mutate({ os: 'android', versao: version.android })
                          setEdit('')
                        }}
                      >
                        Salvar&nbsp;
                        <FaSave />
                      </button>
                    </>
                  }
                </div>
              </InputContainer>

          }
        </Principal>
      </Content>
    </Main>
  )
}
