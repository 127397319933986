import { useQuery } from 'react-query';
import Users from '../../services/User'
import { Main } from '../Empresas/styles'
import Header from '../Header/Header'
import AttToken from '../../services/AttToken';
import { DataGrid, GridColDef, GridLocaleText } from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales'
import { Skeleton, Stack } from '@mui/material';
import { useState } from 'react';
import AddEmpresasModal from './AddEmpresasModal';
import Empresas from '../../services/Empresas';
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { toast } from 'react-toastify';

const userService = new Users();
const empresaService = new Empresas();

export default function UsersList() {
  const [user, setUser] = useState();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const { data, isLoading } = useQuery({
    queryKey: ['GET_USERS'],
    queryFn: async () => {
      const token = await AttToken()
      if (token) {
        const response = await userService.getAdminUsers(token)
        return response
      }
    },
    refetchOnWindowFocus: false,
  })

  const { data: empresasData, isLoading: loadingEmpresas } = useQuery({
    queryKey: ['GET_EMPRESAS'],
    queryFn: async () => {
      toast.warn('Buscando empresas')
      const token = await AttToken()
      if (token) {
        const response = await empresaService.getAll(token)
        return response.empresas
      }
    },
    onSuccess: () => toast.success('Empresas encontradas'),
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  const columns: GridColDef[] = [
    { field: 'nome', headerName: 'Nome', width: 150, align: 'center', headerAlign: 'center' },
    { field: 'id', headerName: 'Nome de usuário', width: 150, align: 'center', headerAlign: 'center' },
    {
      field: "empresas",
      align: 'center',
      headerAlign: 'center',
      headerName: "Empresas associadas",
      sortable: false,
      width: 200,
      renderCell: (params) => (
        !params?.row?.empresas?.length ? <></> :
          <>
            {!open
              ? <button
                disabled={!empresasData}
                onClick={() => setOpen(!open)}
                className='button is-small is-warning'>
                Mostrar empresas&nbsp;&nbsp;<FaEye />
              </button>
              : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <Stack direction={'column'}>
                  {params.row?.empresas?.map((empresaId: string) => (
                    <div>{empresasData?.find((empresa: any) => empresa.id === empresaId).nome}</div>
                  ))}
                </Stack>
                <button onClick={() => setOpen(!open)} className='button is-small is-ghost'>
                  Ocultar&nbsp;&nbsp;<FaEyeSlash />
                </button>
              </div>}
          </>
      )

    },
    {
      field: "edit",
      align: 'center',
      headerAlign: 'center',
      headerName: "Editar associação de empresas",
      sortable: false,
      width: 220,
      renderCell: (params) => (
        <button
          disabled={!empresasData || !data}
          onClick={(event) => {
            event.preventDefault();
            const usuario = data?.find((elem: any) => elem.id === params.id)
            setUser(usuario)
            if (usuario)
              setOpenModal(!openModal)
          }}
          className='button is-small'>
          Adicionar empresas
        </button>
      )
    },
  ];


  const ptBRGrid: Partial<GridLocaleText> = {
    footerRowSelected: (count) =>
      count !== 1
        ? ``
        : ``,
  }

  return (
    <Main>
      {openModal
        && <AddEmpresasModal
          empresas={empresasData}
          isLoading={loadingEmpresas}
          openModal={openModal}
          setOpenModal={setOpenModal}
          user={user}
          setUser={setUser}
        />}
      <Header />
      <span style={{ textAlign: 'center', width: '100%', fontSize: '30px' }}>Usuários Administradores</span>
      <div style={{height: 'fit-content' }}>
        {(!empresasData || !data) && (
          <div>
            <Skeleton variant='circular' height={10} width={10} />
            <span>Trazendo dados... aguarde</span>
          </div>
        )}
        {isLoading ? <Skeleton variant='rounded' height={300} /> :
          <DataGrid
            sx={{ '--css-1jlz3st': 'display:  none' }}
            getRowHeight={() => 'auto'}
            autoHeight
            rows={data}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 25]}
            localeText={ptBRGrid && ptBR.components.MuiDataGrid.defaultProps.localeText}
          />
        }
      </div>
    </ Main>
  )
}
