import { useMutation } from "react-query";
import { ModalCardBody, ModalFooter, P } from "./styles";
import AttToken from "../../services/AttToken";
import { empresaType } from "./EmpresasCadastradas";
import Empresas from "../../services/Empresas";
import queryClient from "../../services/query";
import { toast } from "react-toastify";
import { TextField } from "@mui/material";
import { useState } from "react";

const empresaService = new Empresas();

export default function AddPathModal({
  openModalDominio,
  setOpenModalDominio,
  selectedEmpresa
}:
  {
    openModalDominio: boolean,
    setOpenModalDominio: Function,
    selectedEmpresa: empresaType
  }) {
  const [dominio, setDominio] = useState<string>('')

  const { mutate, isLoading } = useMutation({
    mutationKey: ['ADD_DOMINIO'],
    mutationFn: async () => {
      try {
        const token = await AttToken()
        if (token) {
          const response = await empresaService.setDominio(
            token,
            selectedEmpresa?.id as string,
            dominio
          )
          queryClient.resetQueries(['GET_EMPRESAS'])
          setOpenModalDominio(!openModalDominio)
          return response
        }
      } catch (err) {
        toast.error("Erro ao cadastrar!", {
          position: "top-right",
          autoClose: 5000,
          closeOnClick: true,
          theme: "colored",
        })
      }
    },
    onSuccess: () => {
      toast.success('Dominio cadastrado!', {
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
        theme: "colored",
      })
    },
    onError: () => {
      toast.error("Erro ao cadastrar!", {
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
        theme: "colored",
      })
    }
  })

  function checkChar(value: string): undefined | string {
    if (value === '') {
      return ''
    }
    const pattern = /^[A-Za-z]+$/;
    if (pattern.test(value)) {
      return value;
    }
    return
  }

  return (
    <div className={`${openModalDominio ? 'modal is-active' : 'modal'}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className={`modal-card-head`} style={{ backgroundColor: '#f5f5f5', color: 'gray !important' }}>
          <P className={`modal-card-title`}>Adicionar domínio</P>
          <button
            onClick={() => setOpenModalDominio(!openModalDominio)}
            style={{ backgroundColor: 'red' }}
            className="is-danger delete"
            aria-label="fechar" />
        </header>
        <ModalCardBody className={`modal-card-body`}>
          <TextField
            type="text"
            inputProps={{ maxLength: 8 }}
            value={dominio}
            onChange={
              (even) => {
                const domValue = checkChar(even.target.value)
                if (domValue === undefined) return;
                setDominio(domValue)
              }}
            label='Dominio'
          />
        </ModalCardBody>
        <ModalFooter className={`modal-card-foot`}>
          <div className="buttons">
            <button
              disabled={isLoading || !dominio?.length}
              onClick={() => mutate()}
              className="button is-success">
              Salvar
            </button>
          </div>
        </ModalFooter>
      </div>
    </div>
  )
}
