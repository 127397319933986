import { empresaType } from './EmpresasCadastradas';
import { useMutation } from 'react-query';
import Empresas from '../../services/Empresas';
import AttToken from '../../services/AttToken';
import { toast, ToastContainer } from 'react-toastify';
import { ModalCardBody, ModalFooter, P, Span } from './styles';
import queryClient from '../../services/query';
import { TextField } from '@mui/material';
import { useMask } from '@react-input/mask';

type modalEditProps = {
  openModal: boolean,
  setOpenModal: Function,
  selectedEmpresa: empresaType,
  setSelectedEmpresa: Function,
}

const empresaService = new Empresas();

export default function ModalEditarEmpresa({ openModal, setOpenModal, selectedEmpresa, setSelectedEmpresa }: modalEditProps) {
  const inputRef = useMask({ mask: '__.___.___/____-__', replacement: '_' });

  function checkChar(value: string): undefined | string {
    if (value === '') {
      return ''
    }
    const pattern = /^[A-Za-z]+$/;
    if (pattern.test(value)) {
      return value;
    }
    return
  }

  const { mutate, isLoading } = useMutation({
    mutationKey: ['PUT_EMPRESA'],
    mutationFn: async () => {
      try {
        const token = await AttToken()
        if (token) {
          const empresa = {
            id: selectedEmpresa.id as string,
            nome: selectedEmpresa.nome,
            cnpj: selectedEmpresa.cnpj,
            emailNotificacao: selectedEmpresa.emailNotificacao,
            status: selectedEmpresa?.status?.toLowerCase(),
          }
          const response = await empresaService.editaEmpresa(
            token,
            empresa,
          )
          setSelectedEmpresa({} as empresaType)
          queryClient.resetQueries(['GET_EMPRESAS'])
          setOpenModal(!openModal)
          return response
        }
      } catch (err) {
        setSelectedEmpresa({} as empresaType)
        toast.error("Erro ao cadastrar!", {
          position: "top-right",
          autoClose: 5000,
          closeOnClick: true,
          theme: "colored",
        })
      }
    },
    onSuccess: () => {
      toast.success('Empresa cadastrada!', {
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
        theme: "colored",
      })
    },
    onError: () => {
      toast.error("Erro ao cadastrar!", {
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
        theme: "colored",
      })
    }
  })

  return (
    <>
      <ToastContainer />
      <div className={`${openModal ? 'modal is-active' : 'modal'}`}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className={`modal-card-head`} style={{ backgroundColor: '#f5f5f5', color: 'gray !important' }}>
            <P className={`modal-card-title`}>Editar empresa</P>
            <button
              onClick={() => setOpenModal(!openModal)}
              style={{ backgroundColor: 'red' }}
              className="is-danger delete"
              aria-label="fechar" />
          </header>
          <ModalCardBody className={`modal-card-body`}>
            <TextField
              sx={{ margin: '10px' }}
              label='Nome'
              value={selectedEmpresa?.nome}
              onChange={(event) => setSelectedEmpresa({ ...selectedEmpresa, nome: event.target.value })}
            />
            <TextField
              sx={{ margin: '10px' }}
              label='CNPJ'
              inputRef={inputRef}
              value={selectedEmpresa?.cnpj}
              onChange={(event) => setSelectedEmpresa({ ...selectedEmpresa, cnpj: event.target.value })}
            />
            <TextField
              disabled
              inputProps={{ maxLength: 8 }}
              sx={{ margin: '10px' }}
              label='Domínio'
              value={selectedEmpresa?.dominio}
              onChange={(event) => {
                const domValue = checkChar(event.target.value)
                if (domValue === undefined) return;

                setSelectedEmpresa({ ...selectedEmpresa, dominio: domValue })
              }}
            />
            <TextField
              sx={{ margin: '10px' }}
              label='E-mail de notificação e pesquisa'
              value={selectedEmpresa?.emailNotificacao ? String(selectedEmpresa?.emailNotificacao) : ''}
              onChange={(event) => setSelectedEmpresa({ ...selectedEmpresa, emailNotificacao: event.target.value })}
            />
            <Span>
              Status:
            </Span>
            <select
              onChange={(event) => setSelectedEmpresa({ ...selectedEmpresa, status: event.target.value })}
              value={selectedEmpresa.status}
              className='select is-fullwidth'>
              <option value={('ativo').toLowerCase()}>Ativo</option>
              <option value={('inativo').toLowerCase()}>Inativo</option>
            </select>
          </ModalCardBody>
          <ModalFooter className={`modal-card-foot`}>
            <div className="buttons">
              <button disabled={isLoading} onClick={() => mutate()} className="button is-success">Salvar</button>
            </div>
          </ModalFooter>
        </div>
      </div>
    </>
  )
}